.page-structure-navbar {
    // height: 4vh;
    // margin-bottom: 2vh;
    height: 50px;
    margin-bottom: 12px;
    min-height: 50px;
}

.page-structure-content {
    height: calc(100% - 62px);
}

.page-structure-content.page-structure-content--admin {
    height: 90vh;
    margin-top: 2vh;
}

.page-structure-footer {
    height: 50px;
}

// Remove inherited margin on footer
.page-structure-footer ul.row {
    margin-bottom: 0;
    width: 100%;
}
