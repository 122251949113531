@import 'scss/colors';
@import 'scss/variables';
@import '../node_modules/bootstrap/scss/bootstrap';

@import 'scss/atmos/workspace.scss';
@import 'scss/atmos/page-structure.scss';

body {
  overflow-x: hidden;
  font-family: 'Raleway', sans-serif !important;
}

.e-control, .e-css {
  font-family: 'Raleway', sans-serif !important;
}

.action-menu-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.amp-btn-tiny-2 {
    height: 20px;
    width: 90px;

    line-height: 14px;
    font-size: 12px;
    text-align: center;
    color: #FFFF;

    border-radius: 10px;
    margin: 5px;
}

.amp-btn-lg-2 {
    height: 28px;
    min-width: 90px;
    margin: 5px;

    text-align: center;
    line-height: 14px;
    font-size: 12px;
    font-weight: bold;
    color: #FFFF;
    vertical-align: middle;

    border-radius: 13.5px;
    box-shadow: inset 0 0.5px 0 0 rgba(255,255,255,0.12), inset 0 1px 0 0 rgba(255,255,255,0.03);

    white-space: nowrap;
    text-transform: uppercase;

    padding-left: 6px;
    padding-right: 6px;
}


.amp-btn-lg-3 {
  height: 28px;
  // width: 90px;
  margin: 5px;

  text-align: center;
  line-height: 14px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFF;
  vertical-align: middle;

  border-radius: 13.5px;
  box-shadow: inset 0 0.5px 0 0 rgba(255,255,255,0.12), inset 0 1px 0 0 rgba(255,255,255,0.03);
}


.amp-btn-tiny-2.amp-btn--primary-2, .amp-btn-lg-2.amp-btn--primary-2 {
    background-color: #3075E6;
    border-color: #3075E6;
}

button[disabled].amp-btn-tiny-2.amp-btn-tiny-2.amp-btn--primary-2 {
    opacity: 0.5;
}

.amp-btn-tiny-2.amp-btn--secondary-2, .amp-btn-lg-2.amp-btn--secondary-2 {
    background-color: #24252A;
}

.amp-btn-tiny-2.amp-btn--danger-2 {
    background-color: #dc3545;
    border-color: #dc3545;
}

.amp-btn--danger-2 {
  background-color: #dc3545;
  border-color: #dc3545;
}

#editModeToggle {

}

#editModeToggle .options-img {
    height: 14px;
    width: 14px;
}

#editModeToggle:disabled {
    border: 1px solid #2B72E9;
    border-radius: 5px;
    background-color: #000000;
}

.edit-mode-name-input {
    height: 33px;
    width: 80%;
    border-radius: 2px;
    border-color: #17181B;
    background-color: #17181B;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 29px;
}

.text-large{
  font-size: 1.2rem;
}

.text-small {
  font-size: 0.8rem;
}

.text-xsmall {
  font-size: 0.6rem;
}

.text-grey {
  color: #838383;
}

#amp-div-right
{
  resize:  none;
  background-repeat: no-repeat;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.amp-indent
{
  border-left: 1em !important;
}

.amp-card-header
{
  @extend .card-header;
  border-color:  rgb(102, 102, 102);
  background-color: $backgroundColor ! important;
}

.amp-card
{
  @extend .card;
  border-color:  rgb(102, 102, 102);
  background-color: $backgroundColor ! important;
}


.amp-link
{
  color: cornflowerblue;
  text-decoration: none;
}



.amp-btn-link
{
  @extend .amp-btn;
  font-size: 18px !important;
  margin-top: 2em;
  background-color: rgb(115, 115, 115) ! important;
}


.amp-warning
{
  color: rgb(240, 100, 100);
  font-style: italic;
  font-size: 18pt;
}

label {
  letter-spacing: 1px;
  // font-weight: bold;
  font-size: 13px !important;
}

.logo {
  max-height: 40px;
  padding-left: 22px;
}

.nav-item .amp-tab{
  font-size:14px;
  font-weight: 300;
  opacity: 0.7;
  padding-bottom: 4px;
  transition: opacity 300ms ease-in-out;
}

@media (max-width:940px) {
  .nav-item .amp-tab {
    font-size: 10px;
  }
}

.nav-item .amp-tab:hover {
  opacity: 1;
}

.nav-item {
  // margin-left: 0.75rem;
  // margin-right: 0.75rem;
  text-align: center;
}

.amp-tab.active {
  border-image: linear-gradient(to right, #FD0101, #EFC216, #DE00FF);
  border-width: 3px;
  border-style: solid;
  border-image-slice: 1;
  border-image-width:0 0 3px 0;
  border-top:0;
  border-left:0;
  border-right:0;
  font-weight: 600;
  opacity: 1;
}

.amp-input
{
  width: 25em;
}


Label.amp
{
  font-size: 14pt;
}

p
{
  font-size: 12pt;
}


h2.amp
{
  font-size: 22pt;
}


.amp-btn
{
  @extend .btn;
  width: 24em;
  letter-spacing: 1px;
  text-transform: none;
  font-size: 14px !important;
  color: rgb(248, 245, 244);
  border-color: rgb(100,140,244);
  border-width: thin;
  margin-right: 1em;
  margin-bottom: 4px;
}

.amp-btn-tiny
{
  @extend .amp-btn;
  width: 6em;
  font-size: 13px !important;
}

.amp-btn-sm
{
  @extend .amp-btn;
  width: 11em;
  font-size: 13px !important;
}


.btn-solid {
  background-color: rgb(44,102,193);
  border-style: None !important;
}


.form-control-feedback {
  font-size: 14px !important;
  color: rgb(248, 105, 105);
}

.fill {
  min-height: 100vh;
  height: 100%;
}

.amp-text {
  color: rgb(248, 245, 244) !important;
}

.amp-tab {
  @extend .nav-link;
  @extend .amp-text;
}

.ng-select.AMP
{
  width: 35%;
  color: #f1f1f1;
  float: left;
  margin-right:10px;
  margin-bottom: 15px;
  width: 30%;
  background-color: #d1d1d1;
  color: black;
  display: inline;
}

body.AMP {
  color: #f1f1f1;
  background-color: black;
  vertical-align: center;
}

div.AMP {
  vertical-align: center;
}


.AMP-form-item {
  float: left;
  margin-right:10px;
  margin-bottom: 15px;
  vertical-align: center;
}



.AMP-form-control {
  width: 30%;
  background-color:rgb(248, 245, 244) !important;
  color: black;
  display: inline;
  vertical-align: center;
}

.ng-select.AMP-form-control ::ng-deep {
  width: 30%;
  background-color: rgb(248, 245, 244);
  color: black;
  display: inline;
}


.AMP-form-control-assign-license {
  width: 35%;
}

.AMP-form-name-field {
  width: 60%;
  vertical-align: center;
}

.AMP-add-reseller-container {
  height: 1200px;
}


.AMP-assign-license-container {
  height: 400px;
}



.AMP-license-container {
  width: 1000px;
  height: 800px;
  background-color: black;
}



.AMP-tabs {
  height: 980px;
}

.AMP-form-group {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  clear: both;
}


.AMP-hidden {
  display: none;
}


.AMP-label {
  color: dodgerblue;
  vertical-align: center;
}


.AMP-form-label {
  width: 10pc;
  text-align: right;
}

.AMP-label-content {
  color: whitesmoke;
  font-size: 9pt;
  vertical-align: center;
}

.AMP-row-start {
  padding-left: 8px;
}

.AMP-table-column {
  size: 25pc;
}


.AMP-data {
  color: dodgerblue;
  float: right;
  align: none;
}

.AMP-master {
  background-color: #2D3438;
  width: 38pc;
  float: left;
  height: 620px;
}

.AMP-detail {
  background-color: #2D3438;
  float: right;
  width: 42pc;
  height: 320px;
}

.AMP-detail-gap {
  background-color: #000000;
  opacity: 0.0;
  float: right;
  width: 42pc;
  height: 40px;
}

.AMP-td-gap {
  width: 20px;
}

.AMP-detail-extra {
  background-color: #2D3438;
  float: right;
  width: 42pc;
  height: 260px;
}


.AMP-detail-extra-inside {
  background-color: #2D3438;
  float: right;
  width: 42pc;
  height: 200px;
}

.AMP-detail-extra-gap {
  background-color: #000000;
  opacity: 0.0;
  float: right;
  width: 42pc;
  height: 10px;
}


.AMP-order-height {
  height: 800px;
}




.AMP-button {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 20px;
}

a:link
{
  color:ghostwhite;
}


.AMPModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
 // overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.AMP-login-modal {
  width: 80%;
  height: 40%;
}

.AMP-login-input {
  width: 45%;
}

/* Modal Content */
.AMPModal-content {
  background-color: #0e0e0e;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
  height: 80%;
}


.AMP-add-installation-modal {
  width: 40%;
  height: 30%;
  position: inherit;
}


.AMPModal-content-create-order {
  height: 50%;
}

/* The Close Button */
.AMPModalClose {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.AMPModalClose:hover,
.AMPMOdalClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


.AMP-assign-license-modal {
  width: 50pc;
  height: 20pc;
}


.e-schedule-toolbar-container {
  display: none;
}

tbody tr.e-header-row:nth-child(2) {
  display: none;
}
.e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
  border: none!important;
  background-color: transparent!important;
  color: #8C8C8C!important;
}
.e-schedule {
  background-color: transparent!important;
  border: none!important;
}
.e-time-slots {
  background-color: transparent!important;
}
.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  color: #8C8C8C!important;
}
.e-schedule .e-vertical-view .e-work-cells {
  background-color: transparent!important;
  border: 1px solid #8C8C8C!important;
}
.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td {
  background-color: transparent!important;
  border: none;
}
.e-header-row td:nth-child(1) .resource-name {
  margin: 0px!important;
  padding-left: 20px!important;
  background-image: url('assets/img/icons_ic_music_icon.png')!important;
  background-repeat: no-repeat!important;
}
.e-header-row td:nth-child(2) .resource-name {
  margin: 0px!important;
  padding-left: 20px!important;
  background-image: url('assets/img/icons_ic_video_icon.png')!important;
  background-repeat: no-repeat!important;
}
.e-header-row td:nth-child(3) .resource-name {
  margin: 0px!important;
  padding-left: 20px!important;
  background-image: url('assets/img/icons_ic_action_icon.png')!important;
  background-repeat: no-repeat!important;
}
.e-schedule-table tbody tr:nth-child(1) td:nth-child(1) .e-left-indent-wrap .e-schedule-table tbody tr:nth-child(2) {
  display: none;
}

.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-resource-cells {
  border:none !important;
}

.e-schedule .e-vertical-view .e-all-day-cells:first-child.e-animate {
  display: none;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  vertical-align: top;
}

.popover {
  left:2px!important;
}
.pop-over-body {
  background-color: #0e0e0e!important;
  padding-left: 1em;
  will-change: transform!important;
  transform: translate(0px, -262px)!important;
  width: 100%!important;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #0e0e0e!important;
}
.arrow {
  left: 25%!important;
}

.side-bar-footer-btn button {
  height: 2em!important;
}

/////////////////////////////////
/////////////////////////////////
//
// Programme Scheduler
//
/////////////////////////////////
/////////////////////////////////

.e-appointment {
width: 100% !important;
}

.e-appointment-details {
  padding: 0 !important;
  border: none;
  height: 100%;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  background-color: transparent !important;
  border-radius: 0px!important;
  border-width: 0px !important;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

.appointment-wrap {
  height: 100%;
}

.appointment-wrap.audio {
  background-color: rgba(0,170,174,0.5) !important;
  border-radius: 0px!important;
  border: 1px #00AAAE solid!important;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

.appointment-wrap.audio .type {
  background-color:  #004345 !important;
  color: #00AAAE !important;
  padding: 0 6px;
}

.appointment-wrap.audio .name {
  color: #00F9FF !important;
  padding: 0 6px;
}

.appointment-wrap.video {
  background-color: rgba(105,3,239,0.5) !important;
  border-radius: 0px!important;
  border: 1px #6903EF solid!important;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

.appointment-wrap.video .type {
  background-color: #260453 !important;
  color: #AF7DF0 !important;
  padding: 0 6px;
}

.appointment-wrap.video .name {
  color: #AF7DF0 !important;
  padding: 0 6px;
}

.appointment-wrap .attributes {
  padding: 6px 6px;
}


.appointment-wrap .attributes > * > img {
  margin-right: 6px;
}

.appointment-wrap .attributes > * > .icon-shuffle {
  width: 16px;
  height: 14px;
}

.appointment-wrap .attributes > * > .icon-network-mode {
    width: 16px;
    height: 14px;
}

.appointment-wrap .attributes > * > .icon-loop {
  width: 20px;
  height: 14px;
}

.e-schedule .e-vertical-view .e-work-cells {
  border: 1px rgba(255,255,255,0.18) solid!important;
  border-bottom: 0px!important;
}

.e-dialog {
  background-color: #000000 !important;
  border-radius: 10px !important;
  border: 1px solid #ffffff !important;
}

.e-dlg-header-content, .e-dlg-content, .e-footer-content {
  background-color: transparent !important;
}

.e-dlg-content{
  color: #FFFFFF !important;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  display: none!important;
}

.e-control, .e-css {
  font-family: 'Raleway', sans-serif !important;
}

.e-content-wrap {
  height: 100% !important;
}

.e-schedule .e-vertical-view .e-time-cells-wrap .e-time-cells {
  border: none;
}

.e-schedule .e-content-wrap, .e-schedule .e-scroll-container {
  height: 100% !important;
}

.e-schedule .e-schedule-resource-toolbar {
  background: #2F3035 !important;
  padding: 4px!important;
  margin: 4px 0px !important;
  border-radius: 0.25em;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-menu .e-icon-menu {
  color: white !important;
}

.e-schedule .e-schedule-resource-toolbar .e-resource-level-title {
color: white !important;
}

// .e-schedule .e-resource-tree-popup .e-resource-tree {
//   background: #2F3035 !important;

// }

// .e-schedule-table.e-outer-table > tbody > tr:nth-child(1)  {
//   display: none!important;
// }

// .e-footer-content {
//   background-color: #0e0e0e!important;
//   color: white!important;
// }
// .e-dlg-content {
//   background-color: #0e0e0e!important;
//   color: white!important;
// }
// .e-dlg-header-content {
//   background-color: #0e0e0e!important;
//   color: white!important;
// }
// .e-dialog {
//   background-color: #0e0e0e!important;
//   color: white!important;
// }
// .e-dlg-header .e-title-text {
//   color: white !important;
// }
// .e-icon-dlg-close {
//   color: white!important;
// }
// .e-ddl {
//   color: white!important;
//   background-color: #3D3D3D!important;
//   max-width: 100%;
//   border: 1px solid grey!important;
//   -moz-border-radius: 10px!important;
//   -webkit-border-radius: 10px!important;
// }

// .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
//   background: #232323!important;
// }
// .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {
//   background: #232323!important;
// }

// .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
//   background: #232323!important;
// }
// .e-datetime-wrapper {
//   color: white!important;
// }
// .e-icons {
//   color: white!important;
// }
// .e-event-save {
//   color: white!important;
//   background-color: #226EB0!important;
// }
// .e-event-cancel {
//   color: black!important;
//   background-color: white!important;
// }

/////////////////////////////////
/////////////////////////////////
//
// End of Programme Scheduler
//
/////////////////////////////////
/////////////////////////////////

html, body { height: 100%; }

.bg-nav {
  background-color: #2F3034;
}

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



//////////////////////
//
// DROPDOWN
//
//////////////////////
.dropdown-menu {
  background-color: #2F2F2F;
  padding: 0;
  font-size: 0.8rem;
}

.dropdown-header {
  background-color: #1B1B1B;
  font-size: 0.8rem;
  cursor: default;
}

.dropdown-header, .dropdown-item {
  padding: 0.5rem 0.5rem;
  color: #ffffff;
}

.dropdown-item {
  color: rgba(255,255,255,0.65);
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #1B1B1B;
  color: #FFFFFF;
}

.dropdown-item > img {
  width: 14px;
  margin-right: 0.5rem;
  margin-bottom: 2px;
}

.dropdown-divider{
  padding: 0;
  margin: 0;
  border-top-width: 0.5px;
}

.icon {
  width: 18px;
  margin-bottom: 2px;
}

hr {
  border-top-color: #979797;
  opacity: 0.5;
  margin-top: 0;
}


.modal-content {
  background-color: transparent!important;
}


.dropdown-menu {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px !important;
}

.long-dropdown {
  height: 65vh !important;
}

.cdk-virtual-scroll-viewport {
  height: 100%;
}

.one-line-truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-dark{
  color:#838383!important;
}


.cdk-virtual-scroll-content-wrapper {
  flex-grow: 1 !important;
  position: relative !important;
}


input[type=text], input[type=button], button {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  outline: 0;
  border-style: solid;
  }

select {
  height: 100% !important;
  padding: 0.2rem 0.5rem !important;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 80,0 40,40'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 10px) 8px;
  background-repeat: no-repeat;
  background-color: #efefef;
}


.modal-dialog {
  -webkit-transform: translate(0,-50%);
  -o-transform: translate(0,-50%);
  transform: translate(0,-50%);
  top: calc(50% - 300px);
  margin: 0 auto;
}


.account-dropdown {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid black;
  max-width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control {
  padding: 22px 12px;
  background-color: #18191C;
  border-color: #46474A;
  color: white;
  font-weight: 300;
}

.form-control-label {
  color: #838383;
  font-weight: 300;

}
.modal.license-modal-window .modal-dialog {
    top: calc(50% - 480px);
}



.server-search__container {
    margin-left: 28px;
}

.server-search__wrapper {
    position: relative;
    max-width: 318px;
    min-width: 156px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.server-search__input {
    width: calc(100% - 26px);
    background-color: #2f3033;
    border-radius: 3px!important;
    border-width: 0.5px!important;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.0);
    color: #FFF;
    height: 26px;
    font-size: 14px;
    margin-left: 26px;
    padding: 5px;
    padding-right: 34px;
}

.server-search__button {
    position: absolute;
    right: 1px;
    top: 0;
    background-color: #3075E6!important;
    color: #FFF;
    border-radius: 3px!important;
    border-width: 0;
    margin-top: 1px;
    margin-bottom: 1px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: bold;
    width: 30px!important;
}

.server-search__icon {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 5px;
}

.media-search__container {
    align-items: center;
}

.media-search__input {
    max-width: 318px;
    min-width: 156px;
    width: 100%;
    background-color: #2f3033;
    border-radius: 3px!important;
    border-width: 0px!important;
    color: #FFF;
    height: 26px;
    font-size: 14px;
    padding: 5px;
    margin-right: 12px;
}

.media-search__input::placeholder, .server-search__input::placeholder
{
    color: rgba(255,255,255,0.85)!important;
    opacity: 1!important;
}

.media-search__input:-ms-input-placeholder, .server-search__input:-ms-input-placeholder, {
    color: rgba(255,255,255,0.85)!important;
    opacity: 1!important;
}

.media-search__input::-ms-input-placeholder, .server-search__input::-ms-input-placeholder {
    color: rgba(255,255,255,0.85)!important;
    opacity: 1!important;
}

.ven-inst {
   // opacity: 0.5;
}

.ven-inst.ven-inst__disabled {
    opacity: 0.1;
    cursor: not-allowed;
}
